import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Zoom from '@material-ui/core/Zoom';

// --------------
const useStyles = makeStyles((theme) => ({
    root: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(3),
        [theme.breakpoints.down("sm")]: {
            bottom: theme.spacing(2),
            right: theme.spacing(2),
        }
    },
}))
// --------------
// --------------
// --------------
const ScrollTop = ({ children }) => {
    const [isScrolling, setIsScrolling] = useState(false)
    const classes = useStyles()

    // --------------
    useEffect(() => {
        const handleScroll = () => {
            // are we at the top of the window?
            if (window.pageYOffset < 50) {
                setIsScrolling(false)
            } else {
                setIsScrolling(true)
            }
        }

        window.addEventListener("scroll", handleScroll, { passive: true })
        return () => window.removeEventListener("scroll", handleScroll)
    }, [isScrolling])

    // -------------- 
    const handleClick = (event) => {
        const anchor = (event.target.ownerDocument || document).querySelector('#back-to-top-anchor')
        if (anchor) {
            anchor.scrollIntoView({ behavior: 'smooth', block: 'center' })
        }
    }

    return (
        <Zoom in={isScrolling}>
            <div onClick={handleClick} role="presentation" className={classes.root}>
                {children}
            </div>
        </Zoom>
    )
}

export default ScrollTop
