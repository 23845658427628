import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from "react"
import ReactDOM from "react-dom"
import App from "./App"
import CssBaseline from "@material-ui/core/CssBaseline"
import "./styles/index.css"
import { ThemeProvider } from "@material-ui/core/styles"
import theme from "./ui/theme"
import { BrowserRouter as Router } from "react-router-dom"
import * as serviceWorker from './serviceWorker'

// --------------
ReactDOM.render(
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <Router>
      <App />
    </Router>
  </ThemeProvider>,
  document.getElementById("root")
)

// 🔥 NOTE: app will cache and be hard to get fresh version pitfall! (end user can get stuck on bad version)
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
