import useMediaQuery from "@material-ui/core/useMediaQuery"
import { useTheme } from "@material-ui/core/styles"

// --------------
// --------------  CUSTOM HOOK 🎣
// --------------
const useDeviceSniffer = () => {
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg")) 
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"))
  const isPhone = useMediaQuery(theme.breakpoints.down("xs"))

  // --------------
  return {
    isDesktop,
    isTablet,
    isPhone,
  }
}

export default useDeviceSniffer
