// thx: https://react-theming.github.io/create-mui-theme/
// and https://material.io/resources/color/#!/?view.left=0&view.right=0&primary.color=0277BD&secondary.color=FFE082
import { createMuiTheme } from "@material-ui/core/styles";

const palette = {
  primary: {
    main: "#ffffff", // WHITE
    contrastText: "rgba(0,0,0,0.8)",
  },
  secondary: {
    main: "#91CBF9", // light blue, for AppBar </>
    light: "#e1f5fe", // lighter
    dark: "#18A0FB", // need somewhere ... ACTIVE APPBAR BUTTON BKGND!
    darker: "#036BB1",
    darkest: "#035084",
    accent: "rgb(145, 203, 249, .13)", // baby blue for alert backgrounds, #91cbf9
  },
  info: {
    main: "#C4C4C4", // light gray
    dark: "#828282",
    accent: "rgb(218, 218, 218, .25)", // baby gray, for alert backgrounds: #DADADA
    dialog: "rgb(3, 80, 132, .65)", // for DIALOG backdrop
  },
  yellow: {
    main: "#ffe564",
    light: "#EDC346"
  },
  pink: {
    main: "#FF50BD"
  }
};
const themeName = "KarlGolka";

export default createMuiTheme({ palette, themeName });
