// thx: https://medium.com/@romanonthego/scroll-to-top-with-react-router-and-react-hooks-87ae21785d2f
import { scrollToTop } from '../services/utilities.service'
import { useEffect } from 'react'
import { useRouter } from '../hooks/HookUseRouter'

// Component that attaches scroll to top handler on router change
// renders nothing, just attaches side effects
// -------------- 
// -------------- 
// -------------- 
// -------------- 
const ScrollToTopControlller = () => {
    // this assumes that current router state is accessed via hook
    // but it does not matter, pathname and search (or that ever) may come from props, context, etc.
    const { pathname, search } = useRouter()

    // just run the effect on pathname and/or search change
    useEffect(() => {
        // call our centralized utility
        scrollToTop()

    }, [pathname, search])

    // renders nothing, since nothing is needed
    return null
}

export default ScrollToTopControlller