// thx: https://material-ui.com/components/drawers/#responsive-drawer
// Boom: https://cobwwweb.com/netlify-function-sends-conditional-email
// https://cobwwweb.com/zero-to-functioning-netlify-function
import React from "react"
import AppBar from "@material-ui/core/AppBar"
import CssBaseline from "@material-ui/core/CssBaseline"
import Drawer from "@material-ui/core/Drawer"
import Hidden from "@material-ui/core/Hidden"
import IconButton from "@material-ui/core/IconButton"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import HomeIcon from "@material-ui/icons/Home"
import ListItemText from "@material-ui/core/ListItemText"
import MailIcon from "@material-ui/icons/Mail"
import MenuIcon from "@material-ui/icons/Menu"
import Toolbar from "@material-ui/core/Toolbar"
import Typography from "@material-ui/core/Typography"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import WorkIcon from "@material-ui/icons/Work"
import AccountCircleIcon from "@material-ui/icons/AccountCircle"
import Box from "@material-ui/core/Box"
import { NavLink } from "react-router-dom"
const drawerWidth = 240

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("md")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
    boxShadow: "8px 8px 8px 6px #333",
  },
  drawerBackground: {
    backgroundColor: "transparent",
    // FYI ONLY: backgroundColor: theme.palette.secondary.light,
  },
  listItem: {
    "&:hover": {
      cursor: "pointer",
      backgroundColor: theme.palette.secondary.dark,
    },
  },
  icon: {
    marginRight: "8px",
  },
  appBar: {
    backgroundColor: "#000", // fallback for shitty browsers
    // eslint-disable-next-line no-dupe-keys
    backgroundColor: "rgb(0, 0, 0,0.37)",
    // NOT HERE: opacity: ".37" SEE RGB ABOVE!,
    boxShadow: "none",
    /* For IE8 */
    msFilter: "progid:DXImageTransform.Microsoft.gradient(GradientType=1, StartColorStr='#80000000', EndColorStr='#80000000')",
    /* For IE6,IE7 */
    filter: "progid:DXImageTransform.Microsoft.Gradient(GradientType=1, StartColorStr='#80000000', EndColorStr='#80000000')",
    /* Trigger hasLayout for IE */
    zoom: "1 !important",
    // thx: https://www.lambdatest.com/blog/fixing-browser-compatibility-issues-with-css-opacity-rgba/
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: "rgb(0, 0, 0, .73)",
    boxShadow: "inset 0 -3em 3em rgba(0,0,0,0.8), 0.3em 0.3em 1em rgba(0,0,0,0.7)"
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  navButtons: {
    marginLeft: theme.spacing(1),
    textDecoration: "none !important",
    "&.active": {
      backgroundColor: theme.palette.secondary.darker,
    },
  }
}))

function ResponsiveDrawer(props) {
  const { window } = props
  const classes = useStyles()
  const theme = useTheme()
  const [mobileOpen, setMobileOpen] = React.useState(false)
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"))

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const whiteStyle = {
    color: "white",
  }

  // -------------- DRAWER
  // -------------- DRAWER
  // -------------- DRAWER
  // -------------- DRAWER
  // -------------- DRAWER
  // -------------- DRAWER
  // -------------- DRAWER
  const drawer = (
    <div className={classes.drawerBackground}>
      <Box pl={2} pt={2} className={classes.toolbar}>
        <NavLink
          activeClassName="active"
          exact={true}
          className={classes.navButtons}
          to="/"
          onClick={handleDrawerToggle}
        >
          <Typography variant="h5" noWrap color="secondary" display="block">
            <strong>&lt;/&gt;&nbsp;</strong>
          </Typography>
        </NavLink>
      </Box>
      <List style={{ marginTop: "0", paddingTop: "0" }}>
        {/* ###  */}
        <NavLink
          activeStyle={{
            backgroundColor: theme.palette.secondary.dark,
          }}
          exact={true}
          className={classes.navButtons}
          to="/"
          onClick={handleDrawerToggle}
        >
          <ListItem key="Home" className={classes.listItem}>
            <ListItemIcon className={classes.listItem}>
              <HomeIcon style={whiteStyle} />
            </ListItemIcon>
            <ListItemText primary="Home" style={whiteStyle} />
          </ListItem>
        </NavLink>
        {/* ###  */}
        <NavLink
          activeClassName="active"
          exact={true}
          className={classes.navButtons}
          to="/work"
          onClick={handleDrawerToggle}
        >
          <ListItem key="Work" className={classes.listItem}>
            <ListItemIcon>
              <WorkIcon style={whiteStyle} />
            </ListItemIcon>
            <ListItemText primary="Work" style={whiteStyle} />
          </ListItem>
        </NavLink>
        {/* ###  */}
        <NavLink
          activeClassName="active"
          exact={true}
          className={classes.navButtons}
          to="/about"
          onClick={handleDrawerToggle}
        >
          <ListItem key="About" className={classes.listItem}>
            <ListItemIcon>
              <AccountCircleIcon style={whiteStyle} />
            </ListItemIcon>
            <ListItemText primary="About" style={whiteStyle} />
          </ListItem>
        </NavLink>
        {/* ###  */}
        <NavLink
          activeClassName="active"
          exact={true}
          className={classes.navButtons}
          to="/contact"
          onClick={handleDrawerToggle}
        >
          <ListItem key="Contact" className={classes.listItem}>
            <ListItemIcon>
              <MailIcon style={whiteStyle} />
            </ListItemIcon>
            <ListItemText primary="Contact" style={whiteStyle} />
          </ListItem>
        </NavLink>
      </List>
    </div>
  )

  const logoProps = {
    display: "inline",
    className: "cursor-hover",
  }

  const container =
    window !== undefined ? () => window().document.body : undefined


  // --------------
  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar className={classes.toolBar}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <NavLink
            activeClassName="logo-active"
            exact={true}
            className={classes.navButtons}
            to="/"
          >
            <div style={{ display: "flex" }}>
              <Typography variant="h5" color="secondary" {...logoProps} >
                <strong>&lt;/&gt;&nbsp;</strong>
              </Typography>
              <Typography variant="h6" color="primary" {...logoProps}>
                Karl Golka
            </Typography>
            </div>
          </NavLink>

          {/* ### Secondary Navigation ### */}
          {/* ### Secondary Navigation ### */}
          {/* ### Secondary Navigation ### */}
          {/* ### Secondary Navigation ### */}
          {/* ### Secondary Navigation ### */}
          {/* ### Secondary Navigation ### */}
          {/* ### Secondary Navigation ### */}
          {isDesktop === true && (
            <React.Fragment>
              <div className="secondary-nav" style={{ display: "flex" }}>
                {/* ### */}
                <NavLink
                  activeClassName="active"
                  exact={true}
                  className={classes.navButtons}
                  to="/"
                >
                  <IconButton color="inherit" className={classes.listItem}>
                    <HomeIcon className={classes.icon} />
                    <Typography
                      variant="subtitle1"
                      noWrap
                      color="primary"
                      display="inline"
                    >
                      Home
                  </Typography>
                  </IconButton>
                </NavLink>

                {/* ### */}
                <NavLink
                  activeClassName="active"
                  exact={true}
                  className={classes.navButtons}
                  to="/work"
                ><IconButton color="inherit" className={classes.listItem}>
                    <WorkIcon className={classes.icon} />
                    <Typography
                      variant="subtitle1"
                      noWrap
                      color="primary"
                      display="inline"
                    >
                      Work
                  </Typography>
                  </IconButton>
                </NavLink>

                {/* ### */}
                <NavLink
                  activeClassName="active"
                  exact={true}
                  className={classes.navButtons}
                  to="/about"
                >
                  <IconButton color="inherit" className={classes.listItem}>
                    <AccountCircleIcon className={classes.icon} />
                    <Typography
                      variant="subtitle1"
                      noWrap
                      color="primary"
                      display="inline"
                    >
                      About
                  </Typography>
                  </IconButton>
                </NavLink>

                {/* ### */}
                <NavLink
                  activeClassName="active"
                  exact={true}
                  className={classes.navButtons}
                  to="/contact"
                ><IconButton color="inherit" className={classes.listItem}>
                    <MailIcon className={classes.icon} />
                    <Typography
                      variant="subtitle1"
                      noWrap
                      color="primary"
                      display="inline"
                    >
                      Contact
                  </Typography>
                  </IconButton>
                </NavLink>
              </div>
            </React.Fragment>
          )}
        </Toolbar>
      </AppBar>
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      <Hidden mdUp implementation="css">
        <Drawer
          container={container}
          variant="temporary"
          anchor={theme.direction === "rtl" ? "right" : "left"}
          open={mobileOpen}
          onClose={handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {drawer}
        </Drawer>
      </Hidden>
    </div >
  )
}

export default ResponsiveDrawer
