import React from "react"
import PlayCircleFilledWhiteIcon from "@material-ui/icons/PlayCircleFilledWhite"
import Typography from "@material-ui/core/Typography"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import IconButton from "@material-ui/core/IconButton"
import Button from "@material-ui/core/Button"
import ProgressiveImage from "react-progressive-bg-image"
import useDeviceSniffer from "../../hooks/HookDeviceSniffer"
import { scrollToFullStack } from '../../services/utilities.service'
import FilterHdrIcon from '@material-ui/icons/FilterHdr';

// --------------
const useStyles = makeStyles((theme) => ({
  hero: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  hero101IconButton: {
    color: theme.palette.secondary.dark,
    backgroundColor: "#ffffff",
  },
  hero101Icon: {
    [theme.breakpoints.up("sm")]: {
      fontSize: "4em",
    },
    fontSize: "2em",
  },
  heroTitle: {
    [theme.breakpoints.up("sm")]: {
      marginTop: "24px",
    },
    marginTop: "16px",
    textAlign: "center",
    padding: "0 10px",
    lineHeight: "1.3em",
  },
  heroButton: {
    [theme.breakpoints.up("sm")]: {
      marginTop: "24px",
    },
    marginTop: "16px",
  },
  hero101ActionButton: {
    backgroundColor: theme.palette.secondary.dark,
    color: "white",
  },
  heroProgressiveImage: {
    backgroundSize: "cover",
    backgroundPosition: "right top",
    backgroundAttachment: "fixed",
    paddingBottom: "62px",
    transform: "translate3d(0,0,0)",
    position: "relative"
  },
}))

// --------------
// --------------
// --------------
const Hero = () => {
  const theme = useTheme()
  const fullImage = "/assets/tilt-shifted-MC-HOMEPAGE-BKGND-01.jpg"
  const blurredImage = "/assets/tilt-shifted-HOMEPAGE-BKGND-01-BLURRED.jpg"
  const classes = useStyles()
  const { isDesktop, isTablet } = useDeviceSniffer()

  const heroTopPadding = isDesktop ? "400" : "250"
  const heroTopStyle = {
    paddingTop: `${heroTopPadding}px`,
  }

  return (
    <section className="hero">
      <ProgressiveImage
        src={fullImage}
        placeholder={blurredImage}
        className={classes.heroProgressiveImage}
      >
        <div className="overlay"></div>
        <div className={classes.hero} style={heroTopStyle}>
          <IconButton
            className={classes.hero101IconButton}
            onClick={() => scrollToFullStack(isDesktop)}>
            <PlayCircleFilledWhiteIcon className={classes.hero101Icon} />
          </IconButton>
        </div>
        <div className={classes.hero}>
          <Typography
            variant={
              isDesktop === true ? "h3" : isTablet === true ? "h4" : "h6"
            }
            color="primary"
            display="block"
            className={`${classes.heroTitle} headingBkgnd` }
          >
            Full Stack • <strong style={{color: theme.palette.secondary.main}}> React</strong> • Serverless
          </Typography>
        </div>
        <div className={`${classes.hero} ${classes.heroButton}`}>
          <Button
            onClick={() => scrollToFullStack(isDesktop)}
            variant="contained"
            color="primary"
            className={classes.hero101ActionButton}
            startIcon={<FilterHdrIcon />}
          >
            View Tech Stack
          </Button>
        </div>
        {/* DEVVING  
      <pre style={{ color: "#333", position: "absolute", top: "100px", right: "50px", border: "2px solid yellow", backgroundColor: "rgb(255, 255, 255, .7)", fontSize: "1.4em" }}>{JSON.stringify(styles.fullStackIcons, null, 2)}</pre>


      */}
      </ProgressiveImage>
    </section>
  )
}

export default Hero
