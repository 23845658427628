import React, { Suspense, lazy } from "react"
import ResponsiveDrawer from "./components/layout/ResponsiveDrawer.js"
import Hero from "./components/layout/Hero.js"
import ScrollTop from "./components/ScrollTop.js"
import ScrollToTopControlller from "./components/ScrollToTopControlller.js"
import {
  Switch,
  Route,
} from "react-router-dom"
import Fab from '@material-ui/core/Fab'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

// code-splitting these, lazy-loading, but with PRELOADING!
// thx: https://stackoverflow.com/questions/53252861/react-preloading-components-with-lazy-suspense

// prep the preloading promises (kicks off immediately when the current file is imported)
const prommiseTechStack = import("./components/pages/TechStack.js")
const prommiseWork = import("./components/pages/Work.js")
const prommiseAbout = import("./components/pages/About.js")
const prommiseContact = import("./components/pages/Contact.js")
const prommiseFooter = import("./components/layout/Footer.js")

// and preload! (by the time this gets rendered, your component is probably already loaded)
const TechStack = lazy(() => prommiseTechStack)
const Work = lazy(() => prommiseWork)
const About = lazy(() => prommiseAbout)
const Contact = lazy(() => prommiseContact)
const Footer = lazy(() => prommiseFooter)

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

// --------------
// --------------
// --------------
const App = (props) => {
  const classes = useStyles();

  // --------------
  return (
    <div>
      <ResponsiveDrawer />
      <span id="back-to-top-anchor"></span>
      <Suspense fallback={
        <Backdrop className={classes.backdrop} open={true}>
          <CircularProgress color="inherit" />
        </Backdrop>
      }>
        <Switch>
          {/* ### */}
          <Route path="/full-stack">
            <Hero />
            <TechStack />
          </Route>
          {/* ### */}
          <Route path="/work">
            <Work />
          </Route>
          {/* ### */}
          <Route path="/about">
            <About />
          </Route>
          {/* ### */}
          <Route path="/contact">
            <Contact />
          </Route>
          {/* ### HOME ###  */}
          <Route path="/">
            <Hero />
            <TechStack />
          </Route>
        </Switch>
        {/* Footer */}
        <Footer />
      </Suspense>

      {/* Scroll stuff */}
      <ScrollTop>
        <Fab color="secondary" size="small" aria-label="scroll back to top">
          <KeyboardArrowUpIcon />
        </Fab>
      </ScrollTop>
      <ScrollToTopControlller />

    </div>
  )
}

export default App
